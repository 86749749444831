<template>
    <div class="container-fluid">
        <div v-show="interface_ready">

			<!-- Listing des chevaux en question -->
			<div class="mb-2">
                <b-alert show variant="warning" class="mt-2" v-if="warningDeadHorse != '' ">
                    {{ warningDeadHorse }}
                </b-alert>
				<div class="row d-flex justify-content-between">
					<div class="col-sm-6 text-left text-truncate">
						<router-link v-if="horses_infos.length === 1" :to="{ name: 'horseFiche', params: { horse_id: horses_ids_local[0] }}">
							<font-awesome-icon :icon="['fas', 'horse-head']" /> {{ actualHorseName }}
						</router-link>
						<router-link v-else id="horse_names" :to="{ name: 'horseListe'}">
							<font-awesome-icon :icon="['fas', 'horse-head']" /> {{ actualHorseName }}
						</router-link>
					</div>

					<div class="col-sm-6 text-right">
						{{ horses_infos.length }}
						<span v-if="horses_infos.length === 1">{{ $t('horse.horse') }}</span>
						<span v-else>{{ $t('global.chevaux') }}</span>
					</div>
				</div>

				<div v-if="isEditing || isValidating" class="text-right">{{ index+1 }}/{{ actes_ids_local.length }}</div>

				<b-popover
					target="horse_names"
					triggers="hover"
					placement="right"
				>
					<span v-for="horse in horses_infos" :key="horse.horse_id">
						{{ horse.horse_nom }}<br>
					</span>
				</b-popover>
			</div>

			<!-- Form -->
            <div class="row">
                <form @submit.prevent="saveAndContinue" class="col-12">
                    <!-- Choix d'acte (groupe d'acte) -->
                    <div class="row mb-3">
                        <div class="col-sm-4">
                            <label class="col-form-label">{{ $t('acte.choix_acte') }} *</label>
                            <span v-if="hasLastActetype" class="btn btn-link" @click="setActeTypeWithLast">{{ $t('acte.preselect_last_acte') }}</span>
                        </div>
                        <div class="col-sm-8">
                            <e-select
                                v-model="selected_acte_type"
                                id="actestype_id"
                                track-by="actestype_id"
                                label="actestype_label"
                                :placeholder="$t('acte.selectionnez_acte')"
                                :selectedLabel="$t('global.selected_label')"
                                :options="actes_groupes"
                                
                                @search-change="asyncFind"
                                :internal-search="false"

                                :searchable="true"
                                :allow-empty="false"
                                :loading="isLoadingActeGroupe"
                                :show-labels="false"
                                group-values="actestypes"
                                group-label="actesgroupe_label"
                                :group-select="false"

                                :disabled="isEditingDone"
                            >
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                    </div>

                    <transition name="slide-fade">
                        <div>
                            <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">{{ $t('global.date') }} *</label>
                                <div class="col-sm-4">
                                    <div class="row">
                                        <e-datepicker :disabled="lot && keep_date" class="col" v-model="acte_day" mode="date"></e-datepicker>
                                        <e-datepicker :disabled="lot && keep_date" class="col" v-model="acte_time" mode="time"></e-datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="custom-control custom-checkbox mt-2">
                                        <input type="checkbox" class="custom-control-input" id="realise" v-model="acte_statut" :disabled="date_disable_checkbox || isValidating">
                                        <label class="custom-control-label" for="realise">{{ $t('acte.acte_realise') }}</label>
                                    </div>
                                </div>
                                <div v-if="lot" class="col">
                                    <div class="custom-control custom-checkbox mt-2">
                                        <input type="checkbox" class="custom-control-input" id="keep_date" v-model="keep_date">
                                        <label class="custom-control-label" for="keep_date">{{ $t('acte.keep_date') }}</label>
                                    </div>
                                </div>
                            </div>

							<ErrorAlert v-if="!canEditMultipleHorses" messageI18n="acte.erreur_plusieurs_chevaux" />

                            <!-- Les questions -->
                            <QuestionForm
								ref="QuestionForm"
								v-if="hasChosenActeType && canEditMultipleHorses"
								question_type="horse_actes_type"
								:question_fk="selected_acte_type.actestype_id"
								reponse_type="horse_actes"
								:reponse_fk="actes_ids_local[index]"
								:params_wbs="paramsWbs"
								:update_question.sync="update_question"
                                :preselect="preselect"
								:is_ready.sync="is_question_ready"
                                :pre_reponses="pre_reponses"
                                :horse.sync="horses_infos[0]"
							/>

                            <!-- Bouton pour voir les stocks de paillettes dans le cas d'une IAC -->
                            <div v-if="selected_acte_type && selected_acte_type.actestype_code == 'REPRO_INSMINATION_ARTIFICIELLE_EN_CONGEL'" class="text-right">
                                <span class="btn btn-link" @click="openStockStallion">{{ $t("acte.voir_stocks_semence") }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></span>
                            </div>

                            <!-- Bouton prendre le numéro numéro de chaleur d'une saillie sur un acte de DG -->
                           <!--  <div v-if="selected_acte_type && selected_acte_type.actestype_code == 'REPRO_DIAGNOSTIC_DE_GESTATION'" class="text-right">
                                <span class="btn btn-link" @click="setNumChaleurDG">{{ $t("acte.remplir_dernier_num_chaleur") }}</span>
                            </div> -->

                            <!-- Choix de l'opérateur -->
                            <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">{{ $t('acte.choix_contact') }}</label>
                                <div class="col-sm-8">
                                    <e-select
                                        v-model="selected_contact"
                                        id="contact_id"
                                        track-by="contact_id"
                                        label="contact_label"
                                        :placeholder="$t('acte.selectionnez_contact')"
                                        :selectedLabel="$t('global.selected_label')"
                                        :options="contacts"
                                        :searchable="true"
                                        :allow-empty="true"
                                        :loading="isLoadingContact"
                                        :show-labels="false"
                                        :group-select="false"
                                    />
                                </div>
                            </div>
                            <!-- Commentaire (facultatif) -->
                            <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">{{ $t('formulaire.ajouter_commentaire_interne') }}</label>
                                <div class="col-sm-12">
                                    <textarea class="form-control" :placeholder="$t('formulaire.votre_commentaire')" v-model="acte_commentaire"></textarea>
                                </div>
                            </div>

                            <!-- Articles -->
                            <template v-if="hasFacturationAccess || hasInheritedlicenceFacturationAccess">
                                <div class="row mb-3" v-show="hasChosenActeType">
                                    <label class="col-sm-4 col-form-label">{{ $t('acte.article') }}</label>
                                    <div class="col-sm-8">
                                        <e-select
                                            id="id"
                                            track-by="id"
                                            label="label"
                                            :placeholder="$t('acte.selectionnez_article')"
                                            :selectedLabel="$t('global.selected_label')"
                                            :options="formatted_articles"

                                            group-values="articles"
                                            group-label="accountingplan_label"
                                            :group-select="false"

                                            :searchable="true"
                                            :allow-empty="true"
                                            :loading="isLoadingArticle"
                                            :show-labels="false"
                                            :close-on-select="true"
                                            :class="{ 'is-invalid': errors && errors.indexOf('Articles') > -1 }"
                                            @input="onArticleSelected($event)"
                                        >
                                            <template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
                                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                        </e-select>
                                    </div>
                                </div>

                                <div class="row border-bottom mb-3" v-for="(article_acte, key) in articles_actes" :key="key">
                                    
                                    <div class="col align-self-end">
                                        <div class="form-group">
                                            <span>{{article_acte.label}}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="qte">{{ $t('formulaire.ajouter_commentaire_facture') }}</label>
                                            <textarea class="form-control" :placeholder="$t('formulaire.votre_commentaire')" v-model="article_acte.commentaire"></textarea>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group" v-show="hasFacturationAccess">
                                            <label for="qte">{{ $t('acte.ht') }}</label>
                                            <PriceInput v-model="articles_actes[key].ht"/>
                                        </div>
                                    </div>
                                    <div class="col" v-show="quantifiable && hasFacturationAccess">
                                        <div class="form-group">
                                            <label>{{ $t('acte.quantite') }}</label>
                                            <DecimalInput v-model="articles_actes[key].qte" />
                                        </div>
                                    </div>
                                    <div class="col-auto align-self-end">
                                        <div class="form-group">
                                            <button class="btn btn-secondary" @click.prevent="removeArticle(key)"><font-awesome-icon :icon="['far', 'trash-alt']" /></button>
                                        </div>
                                    </div>
                                    <b-alert 
                                        variant="warning" 
                                        show v-if="articles_actes[key].ht != ((articles.find((article) => article.articles_id == articles_actes[key].id )).articles_ht / 100)"
                                    >{{ $t('formulaire.warning_tarif_diff_article') }}</b-alert>
                                </div>
                            </template>
                        </div>
                    </transition>

                    <!-- Message d'erreur si besoin -->
                    <ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

                    <!-- Bouton de validation du formulaire -->
                    <div v-if="can_edit" class="text-center">
                        <button type="submit" class="btn btn-primary rounded-pill" :disabled="!interface_ready || !hasChosenActeType || !canEditMultipleHorses || !is_question_ready">
                            <div v-if="isValidating"><font-awesome-icon :icon="['far', 'arrow-right']" />  {{ validationButtonTrad }}</div>
                            <div v-else-if="isEditing"><font-awesome-icon :icon="['far', 'pen']" />  {{ $t('global.modifier') }}</div>
                            <div v-else><font-awesome-icon :icon="['far', 'plus']" /> {{ $t('global.ajouter') }}</div>
                        </button>
                        <b-button v-if="isEditing" variant="secondary" rounded pill class="ml-2" @click="confirmDeleteActes">
                            <font-awesome-icon v-if="processing_delete" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'trash-alt']"/> {{ $t('global.supprimer') }}
                        </b-button>
                    </div>
                </form>
            </div>
        </div>
        <div v-show="!interface_ready" class="row">
            <LoadingSpinner class="col-12" />
        </div>
    </div>
</template>

<script type="text/javascript">
    import ActeMixin from "@/mixins/Actes.js"
    import AccountingMixin from "@/mixins/Accounting.js"
    import ActeType from '@/mixins/ActeType.js'
    import Cards from '@/mixins/Cards.js'
    import Contact from "@/mixins/Contact.js"
    import HorseMixin from "@/mixins/Horse.js"
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
    import _sortBy from "lodash/sortBy"
    import Common from '@/assets/js/common.js'
    import ShutterSemence from "@/mixins/shutters-manager/Semence.js"
    import Config from "@/mixins/Config.js"
    import Embryon from '@/mixins/Embryon.js'
    import { EventBus } from 'EventBus'

	export default {
		name: "WriteActe",
        mixins: [ActeMixin, AccountingMixin, ActeType, Cards, Contact, HorseMixin, Shutter, ShutterTriggers, ShutterSemence, Config, Embryon],
        props: {
            horses_ids: { type: Array, default: () => [] },         // Liste des chevaux, utile dans le cas d'un ajout
            actes_ids:  { type: Array, default: () => [] },         // Liste des actes, utile dans le cas d'une validation/édition
            validation: { type: Boolean, default: false },          // Si c'est une validation d'acte(s)
            group_preselect: { type: String, default: '' },         // Pour permettre de préselectionner un groupe dans le formulaire
            type_preselect: { type: String, default: '' } ,         // Pour préselectionner un acte type dans le formulaire
            date_preselect: { type: Date, default: () => {} },
            preselect: { type: String, default: '' },               // Préselectionne une réponse dans les questions (utilisée pour les étalons)
            lot: { type : Boolean, default: false },                // modifier en lot ou non de plusieurs actes
            acte_duplicate: { type : Object, default: () => {}},    // acte à dupliquer
            code_onboarding: { type: String, default: '' }          // code card onboarding (Tableau de bord)
        },
		data () {
			return {
                warningDeadHorse: '',
                loading: false,
				is_question_ready: false,
				acte: null, // Acte en cours
				acte_history: [],
				actes: [],
                actes_ids_local: [],
				grouped_actes_ids: {},
                index: 0,   // Index de l'acte sur lequel on travaille dans la liste prop "actes_ids"
                validation_local: false,
                articles_actes_display: [],
                acte_article_ids: [],

                /* FORM */
                acte_date: new Date(), // Champ modifiable "Date"
                acte_day: new Date(),
                acte_time: new Date(),
                acte_statut: 1, // Champ modifiable "Acte déjà réalisé"
                acte_commentaire: '', // Champ modificable "Commentaire"
                acte_show_commentaire: false, // Champ modificable "Afficher le commentaire sur la facture"

                required_values: [ // Liste des champs obligatoires dans le formulaire
                    'selected_acte_type',
                    'acte_date'
                ],

                actes_groupes: [],
                all_actes_groupes : [], // Tous les actes types disponibles sans filtres
                actes_types: [],  // Tous les actes type pour un groupe seléctionné, non formattés
                //formatted_actes_groupe: [], // Tous les actes type pour un groupe, formattés
                
                articles: [],  // Tous les articles possibles pour cet acte, non formattés
                formatted_articles: [], // Tous les articles possibles pour cet acte, formattés

                selected_acte_groupe: null,
                selected_acte_type: null,
                articles_selected: [],

                quantite: 1,
                quantifiable: false,

                /* FORM STATES */
                isLoadingActeGroupe: true,
                isLoadingActeType: false,
                isLoadingArticle: false,
                errors: [],
                interface_ready: false,  // Indique que le système est correctement initialisé et prêt à gérer les ajouts ou validation d'actes
                message_erreur_code: '', // Message et code d'erreur
                
                update_question: [],

                triggered_list: [],
				isTriggeredActes: false, // Indique si les actes en cours de modification sont des actes "Acte déjà réalisé" déclenchés

				horses_ids_local: [],
				horses_infos: [],

                ht: null,
                acte_article: null,

                can_edit: true,

                articles_actes: [], //On peut désormais ajouter plusieurs articles à mon acte

                saved_elements: null,
                selected_contact: null,
                contacts: [],
                isLoadingContact: true,
                pre_reponses: [],
                keep_date: false,
                last_article_acte_id: null,
                prev_check: '',
                processing_delete: false
			}
		},
		mounted() {
            this.init_component()
		},
		methods: {

            asyncFind (query) {
                if(this.saved_elements === null){
                    this.saved_elements = this.deppCloneObj(this.actes_groupes)
                }

                if(query == ""){
                    this.actes_groupes = this.saved_elements
                    return true
                }

                this.actes_groupes = []
                
                let current_elements = this.deppCloneObj(this.saved_elements)
                let tab_elems = []

                tab_elems = current_elements.filter((elem, key) => {
                    
                    if(elem.actesgroupe_label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                        return true
                    }

                    let display_elem = true
                    
                    const actes_type = elem.actestypes.filter((elem_actetypes) => {
                        if(elem_actetypes.articles.length == 0){
                            display_elem = false
                        }
                        else {
                            let display_article = false
                            elem_actetypes.articles.map(elem_articles => {
                                if(elem_articles.articles_label.toLowerCase().indexOf(query.toLowerCase()) == -1 && display_article == false){
                                    display_elem = false
                                }

                                if(elem_articles.articles_label.toLowerCase().indexOf(query.toLowerCase()) > -1){
                                    display_elem = true
                                    display_article = true
                                }
                                
                            })
                        }

                        if(!display_elem) {
                            if(elem_actetypes.actestype_label.toLowerCase().indexOf(query.toLowerCase()) > -1 || elem_actetypes.actestype_code.toLowerCase().indexOf(query.toLowerCase()) > -1){
                                return true
                            }

                            return false
                        }

                        return true
                    })

                    elem.actestypes = actes_type

                    if(elem.actestypes.length == 0) {
                        return false
                    }

                    return true
                })

                this.actes_groupes = tab_elems
            },
 
            async init_component() {
				this.validation_local = this.validation
                this.loading = true

                let horse_alive = await this.checkHorseAlive(this.horses_ids)
				if(!horse_alive) {
					if(this.horses_ids.length > 1) {
						this.warningDeadHorse = this.getTrad('toast.horse_multiple_dead')
					}
					else {
						this.warningDeadHorse = this.getTrad('toast.horse_unique_dead')
					}
				} else {
					this.warningDeadHorse = ''
				}

                // this.all_actes_groupes = await this.getActesGroupes()
                this.all_actes_groupes = await Common.storage("all_actes_groupes",this.getActesGroupes)
                this.actes_groupes = this.all_actes_groupes

                // Restrictions aux articles actifs.                
                this.actes_groupes.forEach(groupe => { 
                  groupe.actestypes = groupe.actestypes.filter(actetype=> actetype.active || (this.selected_acte_type && actetype.actestype_id == this.selected_acte_type.actestype_id) )
                })

                // Si vient depuis le planning de monte this.type_preselect = "REPRO_SAILLIE_EN_MAIN"
                if(this.type_preselect) {
                    const temp = this.actes_groupes.map(groupe => groupe.actestypes.find(actetype => actetype.actestype_code == this.type_preselect))
                    const type = temp.find(val => val!=undefined)
                    if(type) {
                        this.selected_acte_type = type
                    }
                }


               // await this.format_actes_groupe()
                this.isLoadingActeGroupe = false

                // this.contacts = await this.getAllContact(true)
                // this.isLoadingContact = false

				this.horses_ids_local = []

				if(this.actes_ids.length > 0 && !this.date_preselect) {
					this.loadActes(this.actes_ids)
				}
				else {
					this.actes_ids_local = []
					this.horses_ids_local = this.horses_ids.map((id) => (parseInt(id)))
					this.loadHorsesInfos()
				}

                if(this.date_preselect) {
                    this.acte_date = this.date_preselect
                }

                if(this.acte_duplicate) {
                    this.loadDuplicateActe()
                }

                this.setDateAndTime()

                this.interface_ready = true
                this.loading = false
            },

			// Réduire les actes_ids_local avec un seul de chaque type
			async loadActes(actes_ids) {
				this.loading = true

				this.actes = await this.getActes(actes_ids)

                this.actes_ids_local = []

				// Groupage des actes par actestype_id
                if(this.lot) {
    				this.grouped_actes_ids = {}
    				this.actes.forEach(acte => {
    					if(!this.grouped_actes_ids[acte.actes_actestype]) {
    						this.grouped_actes_ids[acte.actes_actestype] = []
    					}

    					this.grouped_actes_ids[acte.actes_actestype].push(acte.actes_id)
    				})

    				// On garde le premier acte de chaque type
    				for(let actestype_id in this.grouped_actes_ids) {
    					this.actes_ids_local.push(this.grouped_actes_ids[actestype_id][0])
    				}
                }
                else {
                    this.actes.forEach(acte => {
                        this.actes_ids_local.push(acte.actes_id)
                    })
                }

				await this.loadActe()
				this.loading = false
			},

            /* Charge l'acte de la liste actes_ids_local, à l'index this.index */
            async loadActe() {
                this.can_edit = true

                // On récupère les actes à modifier
                this.acte = await this.getActeFromId(this.actes_ids_local[this.index])

                // Vérification si erreur passer au suivant
                if(!this.acte) return this.nextActe('acte.acte_invalide')

                // this.selected_acte_type = this.acte.actetype
                const temp = this.actes_groupes.map(groupe => groupe.actestypes.find(actetype => actetype.actestype_id == this.acte.actes_actestype))
                this.selected_acte_type = temp.find(val => val!=undefined)

                // Restrictions sur les actes actifs ou celui préalablement sélectionné
                this.actes_groupes = this.all_actes_groupes
                // Restrictions aux articles actifs.                
                this.actes_groupes.forEach(groupe => { 
                  groupe.actestypes = groupe.actestypes.filter(actetype=>actetype.active || actetype.actestype_id == this.acte.actetype.actestype_id)
                })

                // si j'ai la facture et que mon acte actuel n'est pas le même que le dernier qui a chargé des actes (bidouille des enfers)
                if(this.hasFacturationAccess && this.last_article_acte_id && this.last_article_acte_id !== this.actes_ids_local[this.index]) {
                    this.load_articles()
                    // this.select_default_article(this.acte.actes_articles)
                }

                this.acte_commentaire = this.acte.actes_commentaire

                // Si on est sur une validation, on auto check le "acte déjà réalisé"
                this.acte_statut = (this.isValidating ? 1 : this.acte.actes_actesstatut)

                // Si on est sur une validation, mettre automatiquement la date du jour si elle est postérieure
				this.acte_date = (new Date(this.acte.actes_date))
				// Chargement de l'heure de l'acte
				const date = Date.parseTz('1970-01-01 ' + this.acte.actes_time)
				this.acte_date.setHours(date.getHours())
				this.acte_date.setMinutes(date.getMinutes())
				this.acte_date.setSeconds(date.getSeconds())

                this.setDateAndTime()

                const date_now = new Date()

				// On check aussi par rapport à la date (on auto check si l'acte est à la date du jour)
				if(!this.acte_statut && this.acte_date <= date_now) {
					this.acte_statut = 1
				}

                // if(this.acte.contact) {
                //     this.selected_contact = this.contacts.find(contact => contact.contact_id == this.acte.contact.contact_id)
                // }

                if(this.isValidating) {
                    this.acte_date = date_now
                    this.acte_date.setHours(date_now.getHours())
                    this.acte_date.setMinutes(date_now.getMinutes())
                    this.acte_date.setSeconds(date_now.getSeconds())
                    this.setDateAndTime()
                }

				//Récupérer les IDs des chevaux des actes en cours d'édition
				const current_actes_ids = this.lot ? this.grouped_actes_ids[this.acte.actetype.actestype_id] : [this.actes_ids_local[this.index]];
				this.horses_ids_local = []
				this.actes.forEach(acte => {
					if(current_actes_ids.includes(acte.actes_id)) {
						this.horses_ids_local.push(parseInt(acte.actes_horse))
					}
				})

				this.loadHorsesInfos()
            },

            loadDuplicateActe() {
                this.acte = this.acte_duplicate

                const temp = this.actes_groupes.map(groupe => groupe.actestypes.find(actetype => actetype.actestype_id == this.acte_duplicate.actetype.actestype_id))
                this.selected_acte_type = temp.find(val => val!=undefined)

                this.acte_commentaire = this.acte_duplicate.actes_commentaire
                this.acte_statut = this.acte_duplicate.actes_actesstatut

                // this.acte_date = (new Date(this.acte_duplicate.actes_date))

                // const date = Date.parseTz('1970-01-01 ' + this.acte_duplicate.actes_time)
                // this.acte_date.setHours(date.getHours())
                // this.acte_date.setMinutes(date.getMinutes())
                // this.acte_date.setSeconds(date.getSeconds())

                this.horses_ids_local = [this.acte_duplicate.actes_horse]

                this.pre_reponses = this.acte_duplicate.reponse

                this.selected_contact = this.contacts.find(contact => contact.contact_id == this.acte.contact.contact_id)

                this.loadHorsesInfos()
                this.load_articles()
            },

			async loadHorsesInfos() {
				// On va récupérer les infos des chevaux concernés
				this.horses_infos = await this.getHorsesWhishlist(this.horses_ids_local)
			},

            async select_default_article() {
                this.ht = null
                this.articles_actes = []
                this.articles_actes_display = []

                if (!this.acte || (this.acte && this.selected_acte_type.actestype_id !== this.acte.actes_actestype)) {
                    // On choisit celui par défaut
                    this.articles.forEach(article => {
                        article.is_selected = !!article.is_default

                        if(article.is_selected)
                        {
                            this.articles_actes_display.push(article)
                        }
                    })
                }
                else {
                    this.acte_article = this.acte && this.acte.actes_articles ? this.acte.actes_articles : null
                    this.acte_article_ids = []
                    if (this.acte_article) {
                        await Common.asyncForEachObject(this.acte_article, async (acte_art) => {
                            let article = this.articles.find(elem => {
                                if(elem.articles_id == acte_art.actesarticles_articles && this.acte_article_ids.indexOf(acte_art.actesarticles_id) == -1)
                                {
                                    this.acte_article_ids.push(acte_art.actesarticles_id)
                                    return elem
                                }
                            })

                            if(article)
                            {
                                article.is_selected = true
                                article.qte = acte_art.actesarticles_quantite
                                article.ht = acte_art.actesarticles_ht
                                article.commentaire = acte_art.actesarticles_commentaire
                                this.articles_actes_display.push(this.deppCloneObj(article))
                            }
                        })
                    }
                }

                this.articles_actes_display.forEach((article) => {
                    if (article.is_selected) {
                        const label = article.articles_label + " (" + article.articles_code + ")"

                        //J'ajoute un élement pour faire apparaitre une ligne d'article
                        if(article.ht == null)
                        {
                            article.ht =  article.articles_ht
                        }

                        if(article.qte == null)
                        {
                            article.qte = 1
                        }

                        this.articles_actes.push({
                            id: article.articles_id,
                            label: label,
                            prefixe: article.articles_code,
                            accountingplan_id: article.accountingplan_id,
                            ht: article.ht / 100,
                            qte: article.qte ? article.qte : (article.articles_quantite ?? 1),
                            commentaire: article.commentaire ? article.commentaire : (article.articles_commentaire ?? '')
                        })
                    }
                })
            },

            /* Vérifie si le formulaire et les réponses aux questions en-dessous sont valides (renvoie true ou false) */
            checkActe() {
                let el = ''
                let error = false

                // On vérifie chaque input obligatoire
                for(let i=0; i<this.required_values.length; i++) {
                    el = this.required_values[i]

                    // On ajoute la classe error sur les div des v-model non remplis
                    if(!this[el]) {
                        error = true
                    }
                }

                if(error) {
                    this.message_erreur_code = 'formulaire.erreur_champs_non_remplis'
                    this.failureToast('formulaire.erreur_champs_non_remplis')
                    return false
                }
                
                // On vérifie également pour les réponses aux éventuelles questions (seulement si l'acte est déjà réalisé)
                if(this.acte_statut){
                    const error_questions = this.$refs.QuestionForm.checkQuestions()
                    if(error_questions) {
                        error = true
                        this.message_erreur_code = error_questions
                        this.failureToast(error_questions)
                        return false
                    }
                }

                this.message_erreur_code = ''
                return true    
            },

            /* Sauvegarde l'acte en cours (ainsi que les réponses aux questions) et passe au suivant */
            async saveAndContinue() {
				this.interface_ready = false
				
                if(!this.checkActe()) {
                    this.interface_ready = true
                    return
				}

				const acte_type = this.selected_acte_type
                const accountingplan_id = this.articles_actes.length > 0 ? this.articles_actes[0].accountingplan_id : null
                const reponses = await this.$refs.QuestionForm.prepareReponses()
                const contact_id = this.selected_contact ? this.selected_contact.contact_id : null
 
                this.setConfig('lastActeTypeSelected', acte_type.actestype_id)

                try {
                    let actes_ids = []
                    // Si c'est une modif/validation, on sauvegarde juste l'acte atuel
                    if(this.isEditing || this.isValidating) {
                        let ids = [this.actes_ids_local[this.index]]
                        if(this.lot) {
                            for(let type in this.grouped_actes_ids) {
                                if(this.grouped_actes_ids[type].includes(this.actes_ids_local[this.index])) {
                                    ids = this.grouped_actes_ids[type]
                                    continue;
                                }
                            }
                        }
                        // const ids = this.lot ? this.grouped_actes_ids[acte_type.actestype_id] : [this.actes_ids_local[this.index]]
                        const no_edit_date = this.lot && this.keep_date
                        await this.updateActe(ids, this.horses_ids_local, this.acte_statut ? 1 : 0, this.acte_date, this.acte_commentaire, acte_type.actestype_id, acte_type.actestypereference_id, accountingplan_id, this.articles_actes, reponses, contact_id, no_edit_date)
                        if(this.hasBreedingAccess && ['REPRO_POULINAGE', 'REPRO_DIAGNOSTIC_DE_GESTATION'].includes(acte_type.actestype_code)) {
                            await this.checkPoulinage([this.actes_ids_local[this.index]])
                        }
                        if(this.hasConfTableAccess) await this.checkAnalyse([this.actes_ids_local[this.index]], acte_type.actestype_code)
                        if(acte_type.actestype_code == 'EVAL_PESEE_TOISE') await this.checkCroissance([this.actes_ids_local[this.index]])

                        actes_ids = ids
						this.addActHistory([this.actes_ids_local[this.index]], acte_type, accountingplan_id, reponses)
					}
                    // Si c'est un ajout, on enregistre les actes pour tous les chevaux séléctionnés en même temps
                    else {
						if (this.acte_date == null) {
							this.acte_date = new Date()
						}

                        if(this.articles_actes.length > 0) {
                            let horse_no_tiers = null
                            let array_horse_nom = []
                            let list_horse_no_tiers = null
                            await Common.asyncForEach(this.horses_ids, async(horse_id) => {
                                horse_no_tiers = await this.checkHorseHasTiersOnDate(horse_id, this.acte_date)
                                if(horse_no_tiers) {
                                    array_horse_nom.push(horse_no_tiers)
                                    list_horse_no_tiers = array_horse_nom.join(', ')
                                }
                            })

                            if(list_horse_no_tiers) {
                                array_horse_nom.length > 1 ? this.infoToast("toast.multiple_horse_no_tiers", {horse_nom: list_horse_no_tiers}) : this.infoToast("toast.unique_horse_no_tiers", {horse_nom: list_horse_no_tiers})
                            }
                        }

                        let horses = this.horses_ids
                        if(this.horses_ids.length == 0) {
                            horses = this.horses_ids_local
                        }
                        actes_ids = await this.addActes(horses, this.acte_statut ? 1 : 0, this.acte_date, this.acte_commentaire, acte_type.actestype_id, acte_type.actestypereference_id, accountingplan_id, this.articles_actes, reponses, null, contact_id)
                        if(this.hasBreedingAccess && ['REPRO_POULINAGE', 'REPRO_DIAGNOSTIC_DE_GESTATION'].includes(acte_type.actestype_code)) {
                            await this.checkPoulinage(actes_ids)
                        }
                        if(this.hasConfTableAccess) await this.checkAnalyse(actes_ids, acte_type.actestype_code)
                        if(acte_type.actestype_code == 'EVAL_PESEE_TOISE') await this.checkCroissance(actes_ids)
                        if(acte_type.actestype_code == 'SUIVI_TEMPERATURE') await this.checkTemperature(actes_ids)

						
						this.addActHistory(this.horses_ids, acte_type, accountingplan_id, reponses)
					}

                    // si je modifie l'acte type d'un acte qui était une transplantation, je libère l'embryon$
                    if(this.acte && this.acte.actetype.actestype_code == 'REPRO_TRANSPLANTATION_EMBRYONNAIRE' && this.selected_acte_type.actestype_id !== this.acte.actetype.actestype_id) {
                        await this.freeEmbryonFromActe(actes_ids[0])
                    }

                    this.successToast("toast.info_save_succes")

                    this.articles_actes = []

                    // https://equideclic.atlassian.net/browse/GROOM-2960 trigger aussi pour les actes planifiés
                    // if(this.acte_statut) {
                        this.updateProposition(actes_ids)
                        await this.$sync.force(true)
                        // ne pas relancer le chargement de l'acte car c'est déjà fait depuis les déclencheurs
                        // if(this.index == this.actes_ids_local.length - 1) {
                        //     this.loadActes(actes_ids)
                        // }
                    // } else {
                    //     this.nextActe()
                    // }
                }
                catch (err) {
					console.error(err)
                    this.failureToast("toast.info_save_failed")
                    this.interface_ready = true
                }
			},

			addActHistory(horse_ids, acte_type, accountingplan_id, reponses) {
				const type = this.isEditing ? 'edit' : (
					this.isValidating ? 'valid' : 'add'
				)

				horse_ids.forEach(horse_id => {
					this.acte_history.push({
						'horse_id'   : horse_id,
						'acte_statut': this.acte_statut ? 1 : 0,
						'acte_date'	 : this.acte_date,
						'acte_commentaire': this.horsesacte_commentaire_ids,
						'acte_type'  : acte_type,
						'accounting_plan': accountingplan_id,
						'articles_selected': this.articles_selected,
						'quantite': this.quantite,
						'reponses': reponses,
						'type': type
					})
				})
			},

            /* Passage à l'acte suivant */
            async nextActe(erreur=null) {
                this.index++
                this.can_edit = true
                // Si il n'y a plus d'actes à charger
                if(this.index >= this.actes_ids_local.length) {
                    if(this.triggered_list.length > 0) {
                        this.shutterPanel().close('act-add-form') 
                        this.shutterPanel().close('act-edit')
                        this.shutterPanel().close('act-valid')
                        const triggered = this.triggered_list[0]
                        this.triggered_list.shift()
                        this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, triggered.horses[0], this.triggered_list, triggered.acte_type, triggered.actes_ids)
                    } else {
                        this.closeForm(erreur)
                    }
                }
                else {
                    this.loading = true
					await this.loadActe()
                    this.loading = false
                }

                this.interface_ready = true

                return !erreur
            },

            /* Ferme ce formulaire, en affichant un éventuel message d'erreur */
            closeForm (erreur=null) {
                if(this.code_onboarding) {
                    this.ok()
                }
                else {
                    if(erreur) this.failureToast(erreur)
                    this.$emit('close')

                    if(this.shutterData.name == 'acte') {
                        this.$router.push({ name: 'planning' })
                    }
                    else {
                        this.ok(this.acte_history)
                        EventBus.$emit('PlanningMonte::refresh')
                    }
                }
            },

            /* Charge les articles possibles pour cet acte */
            async load_articles() {
                // si je suis sur une modification, je set le dernier acte_id avec l'actuel
                if(this.actes_ids_local.length > 0) {
                    this.last_article_acte_id = this.actes_ids_local[this.index]
                }
                this.quantifiable = false
                this.articles_actes = []
                
                if(this.selected_acte_type) {
                    this.isLoadingArticle = true
                    this.articles = this.selected_acte_type.articles//await this.loadAccountingPlanActesTypeArticles(this.selected_acte_type.actestype_id)
                    
                    for(let i in this.articles) {
                        if(this.articles[i].is_quantifiable === 1)
                            this.quantifiable = true; 
					}
					
					this.format_articles()
                }
            },


            /* Formattage pour des objets plus lisibles */
            format_articles() {
                this.formatted_articles = []
                let accountingplan_key = null
                let label = ''

				this.articles_selected = {}

                if(this.articles && this.articles.length > 0) {
                    for(let i = 0; i < this.articles.length; i++) {
                        label = this.articles[i].articles_label + " (" + this.articles[i].articles_code + ")"
						accountingplan_key = this.formatted_articles.filter(accounting_plan => accounting_plan.accountingplan_id == this.articles[i].accountingplan_id)[0]                       

                        if(accountingplan_key) {
                            accountingplan_key.articles.push({
                                id: this.articles[i].articles_id,
                                label: label,
                                prefixe: this.articles[i].articles_code,
                                accountingplan_id: this.articles[i].accountingplan_id,
                                ht: this.articles[i].articles_ht / 100,
                                qte: this.articles[i].articles_quantite ?? 1,
                                commentaire: this.articles[i].articles_commentaire ?? ''
                            })
                        } 
                        else {
                            this.formatted_articles.push({
                                accountingplan_id: this.articles[i].accountingplan_id,
                                accountingplan_label: this.articles[i].accountingplan_label,
                                articles: [{
                                    id: this.articles[i].articles_id,
                                    ht: this.articles[i].articles_ht / 100,
                                    label: label,
                                    prefixe: this.articles[i].articles_code,
                                    accountingplan_id: this.articles[i].accountingplan_id,
                                    qte: this.articles[i].articles_quantite ?? 1,
                                    commentaire: this.articles[i].articles_commentaire ?? ''
                                }]
                            })
                        }
                    }

                    this.select_default_article()

                }

                this.isLoadingArticle = false
                return this.formatted_articles
			},



            /* Change isLoadingActeType (pour pouvoir le faire d'une computed) */
            setIsLoadingActeType(status) {
                this.isLoadingActeType = status
            },

            updateProposition(actes_send) {
                this.interface_ready = true
                this.can_edit = false

                // https://equideclic.atlassian.net/browse/GROOM-2960 trigger aussi pour les actes planifiés
                // if(this.acte_statut) {
                    const horses = this.horses_ids_local

                    this.setupActProposition(horses, this.acte_date, this.selected_acte_type, this.update_question, null, actes_send, async (result) => {
						const actes_ids = []

                        result.forEach(triggered => {
                            if(triggered.type === "acte" && triggered.id) {
                                actes_ids.push(triggered.id)
                            }
							else if(triggered.type === "cheval") { // ajout poulain
								triggered.horses.forEach(horse_id => {
									this.triggered_list.push({
										type: triggered.type,
										horses: [horse_id],
										date: triggered.date,
										actes_ids: actes_send
									})
								})
							}
							else {
                                this.triggered_list.push({
                                    type: triggered.type,
                                    horses: triggered.horses,
                                    date: triggered.date,
                                    acte_type: triggered.acte_type,
                                    actes_ids: actes_send
                                })

                                this.selected_acte_type = {}
                                this.actes = []
                            }
                        })

                        this.shutterPanel().close('proposition-acte')
                        this.shutterPanel().close('horse-selection')
                        this.shutterPanel().close('ajout-acte-dg')
                        this.validation_local = true

						// Merge les actes en attente de modification avec les actes déclenchés
						if(actes_ids.length > 0) {
							let previous_actes_ids = [] // Actes en attente de modification
							if(this.isTriggeredActes) {
								// Récupérer les actes en attente de modification pour les garder dans le formulaire
                                if(this.lot) {
    								Object.keys(this.grouped_actes_ids).forEach(actestype_id => {
    									if(actestype_id != this.selected_acte_type.actestype_id) {
    										this.grouped_actes_ids[actestype_id].forEach(actes_id => {
    											previous_actes_ids.push(actes_id)
    										})
    									}
    								})
                                }
                                else {
                                    this.actes_ids_local.shift()
                                    previous_actes_ids = this.actes_ids_local
                                }
							}

							this.isTriggeredActes = true
							// On ajoute aux actes en attente de modification, les nouveaux actes déjà réalisés déclenchés
							await this.loadActes(previous_actes_ids.concat(actes_ids))
							this.index = -1
						}

                        this.nextActe()
					}, () => {
                        this.shutterPanel().close('proposition-acte')
                        this.shutterPanel().close('horse-selection')
                        this.shutterPanel().close('ajout-acte-dg')
                        this.shutterPanel().close('acte')
                        this.nextActe()
                    })

                // } else {
                //     this.shutterPanel().close('proposition-acte')
                // }
            },

            removeArticle(key){
                this.articles_actes.splice(key, 1);
            },
            onArticleSelected(option) {
                //Je vais regarder si je peux ajouter l'article, s'il est de la même compta que ceux qui sont déja renseignés
                let accounting_plan_diff = this.articles_actes.find(acte_article => {
                    return option.accountingplan_id != acte_article.accountingplan_id
                })

                if(accounting_plan_diff == undefined)
                {
                    let option_clone = this.deppCloneObj(option)
                    this.articles_actes.push(option_clone)
                }
                else
                {
                    this.failureToast('formulaire.actes_only_articles_same_compta')
                }
            },
			async checkOtherActesDate() {
				if(this.horses_ids_local.length == 1 && this.selected_acte_type) {
                    // je vérifie que les infos actuelles ne correspondent pas avec les dernières infos vérifiées pour éviter les doublons de toast
                    const data = JSON.stringify({acte_id: this.actes_ids_local[this.index], horse_id: this.horses_ids_local[0], actestype_id: this.selected_acte_type.actestype_id, date: this.acte_date})
                    if(data == this.prev_check) {
                        return ''
                    }
                    this.prev_check = data
					this.checkDateExists(this.actes_ids_local[this.index], this.horses_ids_local[0], this.selected_acte_type.actestype_id, this.acte_date)
				}
			},
            async preselectOperateur() {
                this.isLoadingContact = true
                const operateur = await this.getOperateurByActeType(this.selected_acte_type.actestype_id, true)
                if(operateur.length == 0) {
                    this.contacts = await this.getAllContact(true)
                }
                else {
                    this.contacts = operateur.map(op => op.contact)
                }
                this.isLoadingContact = false

                if(this.acte) {
                    if(this.acte.contact) {
                        this.selected_contact = this.contacts.find(contact => contact.contact_id == this.acte.contact.contact_id)
                    }
                }
                else if(this.contacts.length == 1) {
                    this.selected_contact = this.contacts[0]
                }
                else if(this.getConfig('contact_logged')) {
                    this.selected_contact = this.contacts.find(contact => contact.contact_id == this.getConfig('contact_logged'))
                }
                // const operateur = await this.getOperateurByActeType(this.selected_acte_type.actestype_id)
                // if(operateur) {
                //     this.selected_contact = this.contacts.find(c => c.contact_id == operateur.actetypeoperateur_contact)
                // }

            },

            async openStockStallion() {
                const question_stallion = this.update_question[0]
                if(!question_stallion.val.uniqueIdentifier) return false

                let horse = null
                if(question_stallion.val.uniqueIdentifier.toString().includes('other')) {
                    const horse_id = question_stallion.val.uniqueIdentifier.replace('other-', '')
                    horse = await this.getHorseById(horse_id)
                }
                else {
                    const sms_id = question_stallion.val.uniqueIdentifier
                    const sms = await this.loadSeasonMareStallion(sms_id)
                    if(!sms) return false
                    horse = sms.horse
                }
                if(horse) {
                    this.setupStallionDetails(horse, null, true)
                }
            },

            setDateAndTime() {
                this.acte_day = new Date(this.acte_date.getFullYear() + '-' +  ('0' + parseInt(this.acte_date.getMonth()+1)).slice(-2) + '-' + ('0' + this.acte_date.getDate()).slice(-2))

                let temp_time = new Date()
                temp_time.setHours(this.acte_date.getHours())
                temp_time.setMinutes(this.acte_date.getMinutes())
                this.acte_time = temp_time
            },

            setActeStatut() {
                this.checkOtherActesDate()

                const date_obj = new Date(this.acte_date)
                const date_now = new Date()
                const diff_date = date_obj.diffWithDate(date_now)
                const date_disable_checkbox = diff_date > 0
    
				if(date_disable_checkbox && this.acte_statut !== 0) {
					this.acte_statut = 0
				}
				else if(!date_disable_checkbox && this.acte_statut == 0) {
					this.acte_statut = 1
				}
            },

            setActeTypeWithLast() {
                if(!this.selected_acte_type) {
                    const actetype_id = this.getConfig('lastActeTypeSelected')
                    if(actetype_id) {
                        const temp = this.actes_groupes.map(groupe => groupe.actestypes.find(actetype => actetype.actestype_id == actetype_id))
                        this.selected_acte_type = temp.find(val => val!=undefined)
                    }
                }
            },

            async setNumChaleurDG() {
                let num = await this.getLastNumChaleur(this.horses_ids_local[0], this.acte_date)
                if(num) {
                    this.$refs.QuestionForm.updateReponse('Numéro de chaleur', num)
                }
                else {
                    this.infoToast('acte.numero_chaleur_empty')
                }
            },

            async confirmDeleteActes() {
                this.processing_delete = true
                await this.deleteActes([this.actes_ids_local[this.index]], this.horses_ids_local)
                this.processing_delete = false
                this.successToast("toast.info_save_succes")
                this.nextActe()
                await this.$sync.force(true)
            }
        },

        computed: {
            date_disable_checkbox: function() {
                const date_obj = new Date(this.acte_date)
                const date_now = new Date()
                const diff_date = date_obj.diffWithDate(date_now)
                return diff_date > 0
            },

            // Trad du bouton de validation
            validationButtonTrad() {
                if(this.isLastAct) {
                    return this.getTrad('global.terminer')
                }
                return this.getTrad('global.suivant')
            },

            // Retourne le nom du cheval en cours
            actualHorseName() {
				return this.horses_infos
					.map(h => h.horse_nom)
					.join(', ')
            },

            // Retoure true si on est sur une modification d'acte (édition ou validation)
            isEditing() {
                return this.actes_ids_local.length > 0
            },

            isEditingDone() {
                return (this.isEditing && this.acte_statut) == true
            },

            // Retourne true si on est sur une validation d'acte(s)
            isValidating() {
                return this.validation_local === true
            },

            // Si la licence a une gamme suffisante pour proposer les articles
            hasFacturationAccess() {
                return this.$store.state.userAccess.hasFacturationAccess
			},
			//Je n'ai pas le droit de voir certaine infos de facture
			hasInheritedlicenceFacturationAccess() {
                return this.$store.state.userAccess.hasInheritedlicenceFacturationAccess
			},

			hasBreedingAccess() {
				return this.$store.state.userAccess.hasBreedingAccess
			},

			hasConfTableAccess() {
				return this.$store.state.userAccess.hasConfTableAccess
			},

            // Retourne true si un acte groupe a été séléctionné
            hasChosenActeGroupe() {
                if(!this.selected_acte_groupe) return false
                return Object.keys(this.selected_acte_groupe).length > 0
            },

            // Retourne true si un acte type a été séléctionné
            hasChosenActeType() {
                if(!this.selected_acte_type) return false
                return Object.keys(this.selected_acte_type).length > 0
            },

			// On ne peut pas modifier un acte avec plusieurs chevaux lorsqu'il y a une liaison vers un seasonMareStallion
			canEditMultipleHorses() {
				if (this.horses_ids_local.length <= 1) {
					return true
				}

				if(!this.hasChosenActeType) {
					return true
				}

				const blacklist = [
					'REPRO_DIAGNOSTIC_DE_GESTATION',
					'REPRO_POULINAGE',
					'REPRO_INSMINATION_ARTIFICIELLE_EN_FRAIS',
					'REPRO_INSMINATION_ARTIFICIELLE_EN_CONGEL',
					'REPRO_INSMINATION_EN_RFRIGR',
					'REPRO_INSMINATION_ARTIFICIELLE_EN_RFRIGR_TRANSPORT',
					'REPRO_SAILLIE_EN_MAIN',
					'REPRO_SAILLIE_EN_LIBERT',
					'REPRO_COLLECTE_DEMBRYON',
					'REPRO_TRANSPLANTATION_EMBRYONNAIRE'
				]

				return !blacklist.includes(this.selected_acte_type.actestype_code)
			},

            paramsWbs() {
                return {
                    horses_ids: this.horses_ids_local,
					actes_id: this.actes_ids_local.length ? this.actes_ids_local[this.index] : null,
                    actes_date: this.acte_date,
                    acte_type_code: this.selected_acte_type.actestype_code
                }
			},

            hasLastActetype() {
                const actetype_id = this.getConfig('lastActeTypeSelected')
                return actetype_id !== null
            }
		},

        // On place des watchers sur les catégories d'actes, pour reload la sous section à chaque changement
        watch: {
            actes_ids (val) {
                this.actes_ids_local = val
            },
            validation (val) {
                this.validation_local = val
            },

            // On surveille le changement de date pour pouvoir déselectionner le check à sa désactivation
            acte_date() {
                this.setActeStatut()
            },

            async selected_acte_type(val) {
				this.checkOtherActesDate()
                this.preselectOperateur()
                // if(!this.acte && (this.hasFacturationAccess || this.hasInheritedlicenceFacturationAccess)) {
                if(this.hasFacturationAccess || this.hasInheritedlicenceFacturationAccess) {
                    this.load_articles()
                }
               if(val && val.actestype_code == 'REPRO_DIAGNOSTIC_DE_GESTATION') {
                    this.setNumChaleurDG()
               }
            },
            async articles_selected(val){
                if(val != null && val.ht != null && (this.acte_article == null || val.id != this.acte_article.actesarticles_articles)) {
                    this.ht = val.ht / 100
                }
                else if(this.acte_article != null) {
                    this.ht =  this.acte_article.actesarticles_ht / 100
                }
            },

            acte_day() {
                this.acte_date = new Date(this.acte_day.getFullYear() + '/' + parseInt(this.acte_day.getMonth()+1) + '/' + this.acte_day.getDate() + ' ' + this.acte_time.getHours() + ":" + this.acte_time.getMinutes())
            },

            acte_time() {
                this.acte_date.setHours(this.acte_time.getHours())
                this.acte_date.setMinutes(this.acte_time.getMinutes())
                this.setActeStatut()
            }
        },
        components: {
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            QuestionForm: () => import('@/components/Questions/QuestionForm'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			PriceInput: () => import('GroomyRoot/components/Inputs/PriceInput'),
			DecimalInput: () => import('GroomyRoot/components/Inputs/DecimalInput')
        }
	}
</script>
